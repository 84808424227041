<template>
  <div class="tadu">
    <!-- <svg version="1.1" class="svg" id="Logo" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 199.4 232.8" xml:space="preserve" ref="teste"> -->
    <svg version="1.1" class="svg" id="Logo" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 191 233.4" xml:space="preserve" ref="teste">
      <!-- <g class="st0">
        <path class="st1" d="M83 220.5c0-.1 0-.3.1-.4l2.6-7.1c.2-.5.4-.7.9-.7s.7.2.9.7l2.6 7.1c0 .1.1.3.1.4 0 .3-.2.5-.5.5-.2 0-.5-.1-.6-.5l-.7-2.1h-3.6l-.7 2.1c-.1.4-.4.5-.6.5-.3 0-.5-.2-.5-.5zm2.2-3.1H88l-1.4-4-1.4 4zM91.9 220.5v-5.8c0-.3.2-.5.5-.5s.5.2.5.5v.7c.5-.8 1.1-1.2 2-1.2s1.5.5 1.5.9c0 .2-.1.5-.5.5-.5 0-.7-.5-1.3-.5-.7 0-1.3.7-1.8 1.4v4c0 .3-.2.5-.5.5s-.4-.2-.4-.5zM97.1 217.4c0-.3.2-.6.6-.6h2.9c.4 0 .6.2.6.6 0 .3-.2.6-.6.6h-2.9c-.4 0-.6-.2-.6-.6z"/>
      </g>
      <g class="st0">
        <path class="st1" d="M129.5 171.4c0-2 1.4-3.4 3.4-3.4 1.8 0 2.8.9 2.8 1.6 0 .3-.2.5-.5.5-.7 0-.7-1.1-2.3-1.1-1.3 0-2.3.8-2.3 2.5s1 2.5 2.3 2.5c1.6 0 1.7-1.1 2.3-1.1.3 0 .5.2.5.5 0 .7-1 1.6-2.8 1.6-2-.2-3.4-1.6-3.4-3.6zM137.6 174.3V166c0-.3.2-.5.5-.5s.5.2.5.5v2.9c.4-.5 1-.9 1.9-.9 1.5 0 2.4 1 2.4 2.4v3.9c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-3.7c0-1-.6-1.6-1.6-1.6s-1.7.7-1.7 1.6v3.7c0 .3-.2.5-.5.5s-.5-.2-.5-.5zM145.3 165.9c0-.8.1-.9.7-.9s.7.1.7.9-.1.9-.7.9-.7-.1-.7-.9zm.2 8.4v-5.8c0-.3.2-.5.5-.5s.5.2.5.5v5.8c0 .3-.2.5-.5.5s-.5-.2-.5-.5zM148.9 171.2c0-.3.2-.6.6-.6h2.9c.4 0 .6.2.6.6 0 .3-.2.6-.6.6h-2.9c-.4 0-.6-.3-.6-.6z"/>
      </g>
      <g class="st0">
        <path class="st1" d="M162.6 118.5c0-.3.1-.4.5-.4h1.1v-1.9c0-.3.2-.5.5-.5s.5.2.5.5v1.9h2.8c.3 0 .5.2.5.4s-.1.4-.5.4h-2.8v3.1c0 .9.5 1.4 1.5 1.4 1.3 0 1.8-.6 2.3-.6.3 0 .5.2.5.4 0 .6-1.3 1.2-2.8 1.2-1.6 0-2.6-.8-2.6-2.3V119H163c-.3 0-.4-.2-.4-.5zM170.4 121c0-2 1.4-3.4 3.3-3.4s3.3 1.4 3.3 3.3c0 .3-.1.5-.5.5h-5c.1 1.4 1 2.1 2.3 2.1s2-.9 2.7-.9c.3 0 .5.2.5.4 0 .7-1.6 1.4-3.1 1.4-2.1.1-3.5-1.4-3.5-3.4zm1.2-.5h4.3c-.1-1.3-1-2-2.2-2-1.1.2-1.9.7-2.1 2zM178.6 121c0-2 1.4-3.4 3.4-3.4 1.8 0 2.8.9 2.8 1.6 0 .3-.2.5-.5.5-.7 0-.7-1.1-2.3-1.1-1.3 0-2.3.8-2.3 2.5s1 2.5 2.3 2.5c1.6 0 1.7-1.1 2.3-1.1.3 0 .5.2.5.5 0 .7-1 1.6-2.8 1.6-2-.1-3.4-1.6-3.4-3.6zM185.9 118.5c0-.3.1-.4.5-.4h1.1v-1.9c0-.3.2-.5.5-.5s.5.2.5.5v1.9h2.8c.3 0 .5.2.5.4s-.1.4-.5.4h-2.8v3.1c0 .9.5 1.4 1.5 1.4 1.3 0 1.8-.6 2.3-.6.3 0 .5.2.5.4 0 .6-1.3 1.2-2.8 1.2-1.6 0-2.6-.8-2.6-2.3V119h-1.1c-.3 0-.4-.2-.4-.5zM193.7 122.7c0-.3.2-.5.5-.5.8 0 .6 1.4 2.4 1.4 1.1 0 1.6-.4 1.6-1.1 0-.7-.5-.9-1.6-1h-.4c-1.2-.1-2.4-.5-2.4-1.8s1.3-1.9 2.6-1.9c1.6 0 2.7.9 2.7 1.6 0 .3-.2.5-.4.5-.8 0-.6-1.2-2.3-1.2-.9 0-1.4.3-1.4.9s.5.7 1.6.9h.4c1.4.2 2.4.7 2.4 2 0 1.4-1.2 2.1-2.8 2.1-1.8-.1-2.9-.9-2.9-1.9z"/>
      </g> -->
       <text x="78" y="214" font-size="6px" fill="white" >Ar—</text>
       <text x="124" y="168" font-size="6px" fill="white" >qui—</text>
       <text x="158" y="118" font-size="6px" fill="white" >tetura</text>
      <path class="st1" d="M37.1 136.8l4.7 12H31.3L64 232.7H48.4l-32.7-83.9h-11l-4.7-12h37.1zM118.7 190.2H103l-6.6-12H84.7l2.7 12H71.7L50 94.3h15.7l53 95.9zm-28.9-23.9l-13.3-24 5.4 24h7.9zM158.6-.1L190 80.5c3.4 8.8-3.1 15.4-14.6 16-14.2.8-23.2-5.3-27.1-15.9L116.8 0h15.7l31.4 80.6c.9 2.2 3.9 4 6.8 4s4.5-1.8 3.6-4L142.9 0l15.7-.1zM124.4 67l24.9 63.9c3.4 8.8-3.1 16-14.6 16h-20.9L76.4 51.1h20.9c11.5-.1 23.7 7.1 27.1 15.9zm-15.6 0c-.9-2.2-3.9-4-6.8-4h-5.2l28 71.9h5.2c2.9 0 4.5-1.8 3.6-4L108.8 67z"/>
    </svg>   
  </div>
</template>

<script>
export default {
  name: 'TaduSvg',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
